<template>
  <draggable class="item*container" tag="div" :list="menuTreeList" group="ustMenuID" >
    <!-- <li v-for="el in menuTreeList" :key="el.menuID"> -->
    <div class="item-group" v-for="(el,index) in menuTreeList" :key="index">
      <div class="item">
        {{ el.baslik }} 
          <span class="menu-closebtn">
            <el-tooltip content='Düzenle' :open-delay="250" placement="bottom">
              <i class="mdi mdi-pen mdi-18px mr-10" style="color: #f7ba2a" @click="updateMenuItem(el)"></i>
            </el-tooltip>
            <el-tooltip content='Yayından Kaldır' :open-delay="250" placement="bottom">
              <i class="mdi mdi-delete mdi-18px" @click="menuSil(el)"></i>
            </el-tooltip>
          </span>
      </div>
      <nested-draggable class="item-sub" :menuList="el.menuList"  :key="refreshMenu"></nested-draggable>
    </div>
    <!-- </li> -->
  </draggable>


</template>
<script>
import draggable from "vuedraggable";
import EventBus from '@/components/event-bus'

export default {
  props: {
    menuList: {
      required: true,
      type: Array
    },
    
  },
  components: {
    draggable
  },
  name: "nested-draggable",
  mounted(){
    this.menuTreeList = this.menuList
    var self = this;
    EventBus.$on("menuListGuncelle", function(menuListeYeni){
      self.menuTreeList = menuListeYeni;
      
    })


  EventBus.$on("refreshMenuList", function(bool){
    self.refreshMenu+=1;
  })

  },

  watch :{
    menuTreeList(val){
      this.refreshMenu += 1;
    }
  },

  methods: {
    updateMenuItem(value){
      EventBus.$emit("menuListStartUpdate", value);
    },
    menuSil(value){
      EventBus.$emit("deleteMenuItem", value);
    }

  },

  data(){
    return {
      menuTreeList: [],
      refreshMenu: 0,
    }
  }
};
</script>


<style scoped lang="scss">
.item-container {
  max-width: 20rem;
  margin: 0;
}
.item {
  padding: 1.2rem;
  // border-radius: 4px;
  border: solid #b8c0c5 1px;
  background: #f3f7fa;
  margin: 1%;
  font-weight: 500 !important;
  font-size: 1.15rem !important;
}

.item-sub {
  margin: 0 0 0 2rem;
}

.menu-closebtn{
  font-size: large;
  position: relative;
  bottom:3px;
  float: right;
  color: #ec205f;
}

.menu-closebtn:hover{
  cursor: pointer;
}
</style>