<template>
    <div class="page-table listPage mainDiv" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t('src.views.apps.menu.title')}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh"></el-button>
                </el-tooltip>
            </div>
        </div>
        
        <el-row :gutter="10">
            <el-form status-icon :model="menuForm" :rules="rulesMenu" ref="menuForm" class="demo-ruleForm" >
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="page-header card-base card-shadow--medium animated fadeInUp pt-20" v-loading="loading || stateLoading"
                    :element-loading-text="loading ? $t('src.views.apps.menu.listLoading') : $t('src.views.apps.menu.deleteLoading')"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(255, 255, 255, 1)">
                        
                        <el-select v-model="menuKategori" placeholder="Menü Seçiniz"  @change="handleChangeMenuType" class="mb-10">
                            <el-option value="1" :label="$t('src.views.apps.menu.anaMenu')"></el-option>
                            <el-option value="2" :label="$t('src.views.apps.menu.footer')"></el-option>
                            <el-option value="3" :label="$t('src.views.apps.menu.icSayfa')"></el-option>
                            <el-option value="4" :label="$t('src.views.apps.menu.ozel')"></el-option>
                        </el-select>
                        
                        <el-col :lg="24" :md="24" :sm="24" :xs="24">
                            <div style="font-style: italic">
                                {{$t('src.views.apps.menu.bilgi')}}
                            </div>
                        </el-col>

                        <el-row class="mt-10">

                            <el-col :lg="15" :md="15" :sm="24" :xs="24">
                                <nested-draggable :key="refreshMenu" :menuList="menuList"/>
                            </el-col>

                            <el-col :lg="9" :md="9" :sm="24" :xs="24" style="position: relative; top: 5px !important">
                                <el-tooltip :content="$t('src.views.apps.menu.menuSiraGuncelle')" :open-delay="250" placement="bottom">
                                    <el-button @click="dialogVisible = true" type="primary" style="float: right;" icon="mdi mdi-update mdi-24px"></el-button>
                                </el-tooltip>
                            </el-col>
                        </el-row>

                    </div>
                </el-col>
                
                <el-col :lg="6" :md="6" :sm="24" :xs="24"  v-loading="addLoading || updateLoading" 
                    :element-loading-text="addLoading ? $t('src.views.apps.menu.addLoading'):  $t('src.views.apps.menu.updateLoading')"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(255, 255, 255, 1)">
                    <div v-if="menuForm.menuSayfalar.length > 0 || update">
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p rightContainerHeader animated fadeInDown">
                                <div>
                                    <p class="rightHeaderText" v-if="!update">{{$t('src.views.apps.menu.menuEkle')}}</p>
                                    <p class="rightHeaderText" v-else-if="update">{{$t('src.views.apps.menu.menuGuncelle')}}</p>
                                </div>
                            </el-col>
                        </el-row>

                        <el-collapse  v-model="activeCollapse" class="yanForm animated fadeInUp" >

                            <el-collapse-item class="card-shadow--medium " name="2" title='Menü Bilgisi'>
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label="$t('src.views.apps.menu.menuBaslik')" prop="baslik">:
                                            <el-input v-model="menuForm.baslik" :placeholder="$t('src.views.apps.menu.menuBaslikPlace')"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item class="margin-top-10">
                                            <el-button size="mini" type="success" v-on:click="iconListDialog = true" icon="el-icon-search">
                                                {{$t('src.views.apps.menu.iconSec')}}
                                            </el-button>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>

                            <el-collapse-item class="card-shadow--medium " name="3" :title="$t('src.views.apps.menu.menuGorunum')">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item label="Menü Konumu Seçiniz" prop="menuGorunum">:
                                            <el-select @change="menuGorunumChange" :placeholder="'Menü Konumu'" v-model="menuForm.menuGorunum" style="width: 100%">
                                                <el-option value="0" :label="$t('src.views.apps.menu.anaMenu')"></el-option>
                                                <el-option value="1" :label="$t('src.views.apps.menu.footer')"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row v-if="menuForm.menuGorunum === '1'">
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label="$t('src.views.apps.menu.ustMenuSeciniz')" :prop="menuForm.menuGorunum === '1' ? 'ustMenu' : ''" >:
                                            <el-select  v-model="menuForm.ustMenu" style="width: 100%" clearable="">
                                                <el-option v-for="(item, index) in menuForm.menuList" :key="index" :label="item.baslik" :value="item.menuID"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                            
                            
                            <el-collapse-item class="card-shadow--medium " name="5" :title="$t('src.views.apps.menu.menudeBulunmayanSayfa')" v-if="!update">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label="$t('src.views.apps.menu.menuSayfaSec')" prop="seciliSayfa">:
                                            <el-radio-group v-model="menuForm.seciliSayfa"  style="width: 100%">
                                                <el-radio size="medium" v-for="(item, index) in menuForm.menuSayfalar" :label="item" :key="index">{{item.baslik}}</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                            

                            <el-collapse-item class="card-shadow--medium " name="1" :title="update === false ? $t('src.views.apps.menu.menuEkle') : $t('src.views.apps.menu.menuGuncelle')">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <span style="float: right">
                                            <el-button v-on:click="clearForm('menuForm')" type="danger">
                                                <label>{{$t('src.views.apps.menu.vazgec')}}</label>
                                            </el-button>

                                            <el-button v-on:click="menuGuncelle('menuForm')" type="primary" v-if="update">
                                                <label>{{$t('src.views.apps.menu.guncelle')}}</label>
                                            </el-button>

                                            <el-button v-on:click="menuTanimla('menuForm')" type="success" v-else-if="!update">
                                                <label>{{$t('src.views.apps.menu.kaydet')}}</label>
                                            </el-button>
                                        </span>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>

                        </el-collapse>
                    </div>
                    <div v-else style="background-color: white; height: 150px; display: flex; justify-align: center; padding: 10px; text-transform: capitalize">
                        <div style="font-style: italic">{{$t('src.views.apps.menu.eklenecekSayfaYok')}}</div>
                    </div>
                </el-col>
                
            </el-form>
        </el-row>


        <el-dialog :title="$t('src.views.apps.menu.islemiOnayla')" :visible.sync="dialogVisible" width="20%">
            <el-row type="flex" justify="center">
                <el-col :lg="12" :md="12" :sm="24" :xs="24">
                    <el-tooltip :content="$t('src.views.apps.menu.islemiOnayla')" :open-delay="100" placement="bottom">
                        <el-button style="float: left" v-on:click="menuSiraGuncelle" type="success" icon="mdi mdi-check">
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :content="$t('src.views.apps.menu.vazgec')" :open-delay="100" placement="bottom">
                        <el-button style="float: right" v-on:click="dialogVisible = false" type="danger" icon="mdi mdi-close"></el-button>
                    </el-tooltip>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog :title='$t("src.views.apps.menu.iconList")' :visible.sync="iconListDialog" width="60%" center>
            <el-row class="iconRow">
                <el-input class="margin-bottom-10" :placeholder="$t('src.views.apps.sayfa.guncelleme.listedeOlmayanIcon')" size="medium" v-model="menuForm.icon"></el-input>
                <el-col v-for="(item,index) in iconListe" :key="item" :label="item" :value="item" :lg="4" :md="4" :sm="8" :xs="8" style="border: 1px solid #9a9a9a; padding: 5px; height: 100px; text-align: center;display:table">
                    <div v-on:click="iconSelect(item)" class="iconCerceve" id="#iconCerceve">
                        <el-button type="text">
                            <i style="font-size: 24px;text-align: center" :class="item"></i>
                        </el-button>
                        <br>
                        <span class="icon-name" :data-class="item">{{item}}</span>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>

    </div>
</template>

<script>
    import nestedDraggable from "../../../components/NestedDraggable.vue";
    import menuService from "../../../WSProvider/MenuService";
    import menuKategoriService from "../../../WSProvider/MenuKategoriService";
    import sayfaService from "../../../WSProvider/SayfaService";
    import notification from "../../../notification";
    import functions from "../../../functions";
    import EventBus from '@/components/event-bus'
    import iconJson from '../../../icon'
    import Jquery from "jquery";

    const $ = Jquery;

    export default{
        components: {nestedDraggable},
        display: "Nested",
        order: 15,
        watch: {
            menuList(val){
                EventBus.$emit("menuListGuncelle" ,val);
            },
        },
        data(){
            return {
                menuKategori: "1",
                //Onay dialog
                dialogVisible: false,
                update: false,
                iconListDialog: false,

                //draggable
                menuList: [],
                menuListCopy: [],
                filterMenuKategori: "",
                altMenulerListe: [],
                refreshMenu: 0,
                iconListe: iconJson["v5.0.9"],

                //menu ekleme form
                menuForm: {
                    menuID: "",
                    baslik:"",
                    menuSayfalar: [],
                    allMenuList: [],
                    menudeOlanSayfalar:[],
                    ustMenu: {},
                    seciliSayfa: {},
                    menuKategoriListe: "",
                    menuKategoriID: "",
                    seciliMenuKategori: "",
                    menuGorunum:"",
                    icon: "",
                },

                loading: false,
                stateLoading: false,
                updateLoading: false,
                addLoading: false,
                selectIcon: "1",
                activeCollapse: ["1","2","3","4","5","6","7","8","9"],

                
                rulesMenu: {
                    baslik: [{required: true, message: "Lütfen Başlık Giriniz", trigger: "blur"}],
                    menuGorunum: [{required: true, message: "Lütfen Menü Konumu Seçiniz", trigger: "blur"}],
                    ustMenu: [{required: true, message: "Lütfen Üst Menü Seçiniz", trigger: "blur"}],
                    seciliSayfa: [{required: true, message: "Lütfen Menü Konumu Seçiniz", trigger: "blur"}],
                    // menuKategoriID: [{required: true, message: "Lütfen Menü Kategori Seçiniz", trigger: "blur"}],
                },
            }
        },
        created(){
            this.getMenuList(this.menuKategori);
            this.getMenuSayfalar(0); // 1 menude olan, 0 menude olmayan
            this.getMenuKategoriListe();
            
            const self = this;
            EventBus.$on("menuListStartUpdate", function(value){
                self.startUpdate(value);
            })
            EventBus.$on("deleteMenuItem", function(value){
                this.loading = true;
                self.deleteMenuItem(value);
            })            
        },
        methods: {
            refreshPage(){
                this.loading = false;
                this.stateLoading = false;
                this.addLoading = false;
                this.updateLoading = false;
                this.getMenuList(this.menuKategori);
                this.getMenuSayfalar(0); // 1 menude olan, 0 menude olmayan
                this.getMenuKategoriListe();
            },

            handleChangeMenuType(val){
                this.getMenuList(val);
            },

            deleteMenuItem(value){
                try{
                    this.stateLoading = true;
                    menuService.menuSil(value.menuID, value.link).then(response => {
                        if(response.status == 200 ){
                            localStorage.setItem("userDataDemirag", response.token);
                            this.getMenuList("1");
                            this.menuKategori = "1";
                            this.getMenuSayfalar(0);
                        }
                        notification.Status("success", this, response.msg);
                        this.stateLoading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.stateLoading = false;
                    })
                }catch(e){
                    this.stateLoading = false;
                }
            },

            startUpdate(value){
                this.menuForm.menuList = editMenuList(this.menuList)
                this.update = true;
                this.menuForm.menuID = value.menuID;
                this.menuForm.baslik = value.baslik;
                this.menuForm.icon = value.icon;
                this.menuForm.ustMenu = value.ustMenuID;
                this.menuForm.menuGorunum = value.ustMenuID != "0" ? "1" : "0";
                this.menuForm.menuKategoriID = value.menuKategoriID;
            },

            menuGuncelle(formName){
                if(this.validateForm(formName)){
                    try{
                        this.updateLoading = true;
                        menuService.menuGuncelle(this.menuForm.menuID, this.menuForm.menuGorunum === '1' ? this.menuForm.ustMenu : this.menuForm.menuGorunum, this.menuKategori, this.menuForm.baslik, this.menuForm.icon).then(response => {
                            if(response.status == 200 ){
                                localStorage.setItem("userDataDemirag", response.token);
                                notification.Status("success", this, response.msg)
                                this.menuKategori = "1";
                                this.getMenuList(1);
                                this.getMenuSayfalar(0);
                                this.resetForm();
                            }
                            this.updateLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.updateLoading = false;
                        })
                    }catch(e){
                        this.updateLoading = false;
                    }
                }
            },

            menuGorunumChange(val){
                if(val === "1"){
                    this.menuForm.menuList = editMenuList(this.menuList)
                    this.menuForm.ustMenu = this.menuForm.menuList.length > 0 ? this.menuForm.menuList[0].menuID : ""
                }
            },

            resetForm(){
                this.$refs["menuForm"].resetFields();
            },

            menuTanimla(formName){
                if(this.validateForm(formName)){
                    try{
                        this.addLoading = true;
                        menuService.menuTanimla(this.menuForm.menuGorunum === '1' ? this.menuForm.ustMenu : this.menuForm.menuGorunum, this.menuKategori, this.menuForm.icon, this.menuForm.baslik, this.menuForm.seciliSayfa.link).then(response => {
                            if(response.status == 200 ){
                                localStorage.setItem("userDataDemirag", response.token);
                                this.getMenuList(this.menuKategori);
                                this.getMenuSayfalar(0)
                                this.resetForm();
                            }
                            notification.Status("success", this, response.msg)
                            this.addLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.addLoading = false;
                        })
                    }catch(e){
                        this.addLoading = false;
                    }
                }
            },

            clearForm(formName){
                this.$refs[formName].resetFields();
                this.menuForm.menuGorunum = "0";
                this.menuForm.menuKategoriID = "";
                this.update = false;
            },

            getMenuSayfalar(durum){
                if(durum == 0){
                    // 0 = menude olmayan sayfalar
                    sayfaService.menuSayfalar(durum).then(response => {
                        if(response.status == 200 ){
                            localStorage.setItem("userDataDemirag", response.token);
                            this.menuForm.menuSayfalar = response.data;               
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404) {
                                this.menuForm.menuSayfalar = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                    })
                }
            },

            iconSelect(value) {
                this.menuForm.icon = value;
                $("body").find(".aktifIkon").removeClass("aktifIkon")
                $(".iconRow").find('span[data-class="' + value + '"]').closest(".iconCerceve").parent("div").addClass("aktifIkon");
                notification.Status("success", this, value + " icon seçildi.")
            },

            getMenuList(menuKategoriID){
                try{
                    this.loading = true;
                    menuService.menuListe(menuKategoriID).then(response => {
                        if(response.status === 200 ){
                            localStorage.setItem("userDataDemirag", response.token);
                            response.data.forEach(el => {
                                el = functions.removeSlashesAndDecodeStrings(el)
                            })
                            this.menuList = [...response.data];
                            this.menuListCopy = [...response.data];
                            this.menuList.forEach(element => {
                                element["menuList"] = [];
                            });
                            this.getSubMenu()               
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.menuList = [];
                                this.menuLisCopy = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },

            getSubMenu(){
                for(var i = 0;  i < this.menuList.length ;i++){
                    for(var j = 0; j < this.menuList.length; j++){
                        if(this.menuList[j].ustMenuID !== "0" && this.menuList[i].menuID === this.menuList[j].ustMenuID){
                            this.menuList[i]["menuList"].push(this.menuList[j])
                            this.altMenulerListe.push(this.menuList[j]);
                            this.checkSubInSubMenu(this.menuList[j]);
                        }
                    }
                }
                
                this.altMenulerListe.forEach((element, index) => {
                    var i = this.menuList.findIndex(item => item.menuID === element.menuID && item.ustMenuID != '0');
                    if(i >= 0) this.menuList.splice(i, 1);
                });


                this.refreshMenu+=1;
            },

            checkSubInSubMenu(element){
                for(var i = 0;  i < this.menuList.length ;i++){
                    var index = this.menuList[i]["menuList"].findIndex(el => el === element);
                    if(element.menuID === this.menuList[i].ustMenuID && index < 0){
                        // element["menuList"].push(this.menuList[i]);
                        this.altMenulerListe.push(this.menuList[i]);
                        this.checkSubInSubMenu(this.menuList[i])
                    }
                }
            },

            getMenuKategoriListe(){
                menuKategoriService.menuKategoriListele().then(response => {
                    if(response.status === 200 ){
                        localStorage.setItem("userDataDemirag", response.token);
                        this.menuForm.menuKategoriListe = response.data;         
                    }
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                    }else{
                        //notification.Status(503, this)
                    }
                })
            },


            menuSiraGuncelle(){
                let result = editMenuList(this.menuList);
                menuService.menuListeGuncelle(this.menuKategori, result).then(response => {
                    if(response.status === 200 ){
                        localStorage.setItem("userDataDemirag", response.token);
                        notification.Status("success", this, "Menu Başarıyla Güncellenmiştir.")
                        this.dialogVisible = false;
                    }
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                    }else{
                        //notification.Status(503, this)
                    }
                })
            },

        }
    }

    let siraNo = 1;
    let menuID = 0;
    let resultMenu = [];

    function editMenuList(menu){
        resultMenu = [];
        siraNo = 0;
        menuID = 0;
        menu.forEach(element => {
            element.siraNo = siraNo;
            element.ustMenuID = 0;
            resultMenu.push(element);
            siraNo += 1;
            element.miniMenu = "0";
            if(checkSubMenu(element)){
                menuID = element.menuID;
                calcSubMenu(element, menuID);
            }
        });
        return resultMenu;
    }

    function calcSubMenu(element, id){
        element.menuList.forEach(e => {
            e.siraNo = siraNo;
            e.ustMenuID = id;
            siraNo += 1;
            resultMenu.push(e);
            e.miniMenu = "0";
            if(checkSubMenu(e)){
                calcSubMenu(e, e.menuID);
                setMiniMenu(e);
            }
        })
    }

    function setMiniMenu(element){
        element.menuList.forEach(e => {
            e.miniMenu = "1";
        })
    }

    function checkSubMenu(element){
        if(element.menuList.length > 0) {
            return true;
        }
        return false;        
    }


</script>


<style scoped lang="scss">
    @import '../../../assets/scss/_variables';
    .el-radio-group{
        .el-radio{
            margin: 1% 0px 0px 4%;
            display: block !important;
        }
    }

    .rightHeaderText{
        // font-weight: bold !important;
        font-size: 22px !important;
    }

    .rightContainerHeader{
        position: relative;
        border-radius: 5px;
        border-bottom: 3px solid $text-color-accent; 
        margin-bottom: 5px;
        background-color: $text-color-accent;

        div{
            height: 50px; 
            display:flex; 
            align-items:center;
            justify-content: center;
            color: white;
        }
    }
</style>

